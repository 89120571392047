import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import image from './ncitechlogo.png';

const Navbar = () => {
  return (
    <AppBar position="static" style={{backgroundColor: '#111827'}} className='py-2'>
      <Toolbar>
        <img src={image} alt='' className="h-14 w-14" />
        <Button style={{color: '#83c5be'}} component={Link} to="/">Home</Button>
        <Button style={{color: '#83c5be'}} component={Link} to="/events">Events</Button>
        <Button style={{color: '#83c5be'}} component={Link} to="/news">News</Button>
        <Button style={{color: '#83c5be'}} component={Link} to="/contact">Contact</Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
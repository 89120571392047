import React from 'react';
import { Card, CardContent, Link } from '@mui/material';

export default function Component() {
  return (
    <div className="flex flex-col min-h-[100dvh]">
      <header className="bg-gray-900 text-white py-12 md:py-20">
        <div className="container mx-auto px-4 md:px-6 flex flex-col items-center text-center">
          <div className="inline-flex items-center gap-2 mb-4">
            <h1 className="text-3xl font-bold">Tech Club</h1>
          </div>
          <p className="max-w-md text-gray-300">
            Explore the latest advancements in technology and connect with like-minded individuals at our upcoming
            events.
          </p>
        </div>
      </header>
      <main className="py-12 md:py-20">
        <div className="container mx-auto px-4 md:px-6">
          <h2 className="text-2xl font-bold mb-8">Upcoming Events</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <Card>
              <CardContent>
                <h3 className="text-xl font-bold mb-2">Introduction to Machine Learning</h3>
                <div className="flex items-center gap-2 mb-4">
                  <CalendarIcon className="w-4 h-4" />
                  <span>June 15, 2024</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <ClockIcon className="w-4 h-4" />
                  <span>5:30 PM - 7:30 PM</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <MapPinIcon className="w-4 h-4" />
                  <span>Hlab 3.03, May Main Building Level 3</span>
                </div>
                <p className="text-gray-500">
                  Join us for an in-depth introduction to the world of machine learning. Learn about the fundamentals,
                  common algorithms, and practical applications.
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <h3 className="text-xl font-bold mb-2">Web Development Workshop</h3>
                <div className="flex items-center gap-2 mb-4">
                  <CalendarIcon className="w-4 h-4" />
                  <span>June 25, 2024</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <ClockIcon className="w-4 h-4" />
                  <span>7:00 PM - 9:00 PM</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <MapPinIcon className="w-4 h-4" />
                  <span>Online</span>
                </div>
                <p className="text-gray-500">
                  Dive into the world of web development and learn the latest techniques and frameworks. This hands-on
                  workshop will cover HTML, CSS, and JavaScript.
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <h3 className="text-xl font-bold mb-2">Cybersecurity Fundamentals</h3>
                <div className="flex items-center gap-2 mb-4">
                  <CalendarIcon className="w-4 h-4" />
                  <span>July 1, 2024</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <ClockIcon className="w-4 h-4" />
                  <span>6:00 PM - 8:00 PM</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <MapPinIcon className="w-4 h-4" />
                  <span>Online</span>
                </div>
                <p className="text-gray-500">
                  Learn the fundamentals of cybersecurity, including network security, threat detection, and best
                  practices for protecting your data.
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <h3 className="text-xl font-bold mb-2">Introduction to Machine Learning</h3>
                <div className="flex items-center gap-2 mb-4">
                  <CalendarIcon className="w-4 h-4" />
                  <span>June 15, 2024</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <ClockIcon className="w-4 h-4" />
                  <span>5:30 PM - 7:30 PM</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <MapPinIcon className="w-4 h-4" />
                  <span>Hlab 3.03, May Main Building Level 3</span>
                </div>
                <p className="text-gray-500">
                  Join us for an in-depth introduction to the world of machine learning. Learn about the fundamentals,
                  common algorithms, and practical applications.
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <h3 className="text-xl font-bold mb-2">Web Development Workshop</h3>
                <div className="flex items-center gap-2 mb-4">
                  <CalendarIcon className="w-4 h-4" />
                  <span>June 25, 2024</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <ClockIcon className="w-4 h-4" />
                  <span>7:00 PM - 9:00 PM</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <MapPinIcon className="w-4 h-4" />
                  <span>Online</span>
                </div>
                <p className="text-gray-500">
                  Dive into the world of web development and learn the latest techniques and frameworks. This hands-on
                  workshop will cover HTML, CSS, and JavaScript.
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <h3 className="text-xl font-bold mb-2">Cybersecurity Fundamentals</h3>
                <div className="flex items-center gap-2 mb-4">
                  <CalendarIcon className="w-4 h-4" />
                  <span>July 1, 2024</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <ClockIcon className="w-4 h-4" />
                  <span>6:00 PM - 8:00 PM</span>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <MapPinIcon className="w-4 h-4" />
                  <span>Online</span>
                </div>
                <p className="text-gray-500">
                  Learn the fundamentals of cybersecurity, including network security, threat detection, and best
                  practices for protecting your data.
                </p>
              </CardContent>
            </Card>
          </div>
        </div>
      </main>
      <footer className="bg-gray-900 text-white py-6">
        <div className="container mx-auto px-4 md:px-6 flex items-center justify-between">
          <p className="text-sm">&copy; 2024 Tech Club</p>
          <nav className="flex items-center gap-4">
            <Link href="#" className="text-sm hover:underline" prefetch={false}>
              About
            </Link>
            <Link href="#" className="text-sm hover:underline" prefetch={false}>
              Contact
            </Link>
            <Link href="#" className="text-sm hover:underline" prefetch={false}>
              Privacy Policy
            </Link>
          </nav>
        </div>
      </footer>
    </div>
  )
}



function CalendarIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8 2v4" />
      <path d="M16 2v4" />
      <rect width="18" height="18" x="3" y="4" rx="2" />
      <path d="M3 10h18" />
    </svg>
  )
}


function ClockIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <polyline points="12 6 12 12 16 14" />
    </svg>
  )
}


function MapPinIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
      <circle cx="12" cy="10" r="3" />
    </svg>
  )
}
import React from 'react';

function News() {
  return (
    <div>
      <h1>News</h1>
      <p>Coming Soon</p>
    </div>
  );
}

export default News;